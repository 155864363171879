import React from "react";
import { Route, Routes } from "react-router-dom";
import { getSession } from "./api";
import "./App.css";
import { AppContext } from "./context";
import { SignIn } from "./SignIn";
import { SignUp } from "./SignUp";
import { Box, VStack, useColorMode } from "@chakra-ui/react";
import { User } from "./types";
import { Home } from "./Home";
import { NavBar } from "./NavBar";
import { Landing } from "./Landing";

function App() {
  const [user, setUser] = React.useState<null | User | undefined>();
  const { toggleColorMode, colorMode } = useColorMode();
  React.useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode();
    }
  }, [colorMode]);
  React.useEffect(() => {
    const fetchSession = async () => {
      try {
        const data = await getSession();
        if (data) {
          setUser(data);
        } else {
          setUser(null);
        }
      } catch (e) {
        setUser(null);
      }
    };

    fetchSession();
  }, []);

  React.useEffect(() => {
    document.title = "BALLDONTLIE API";
  }, []);

  if (user === undefined) {
    // Loading session
    return null;
  }

  return (
    <AppContext.Provider value={{ user: user as any, setUser }}>
      <VStack w="100%" h="100%">
        <NavBar />
        <Box w="100%" h="100%" p={4}>
          <Routes>
            <Route path="/" element={user?.email ? <Home /> : <Landing />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </Routes>
        </Box>
      </VStack>
    </AppContext.Provider>
  );
}

export default App;
