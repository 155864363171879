import { Box, Button, Divider, HStack, VStack } from "@chakra-ui/react";
import { useAppContext } from "./hooks";
import React from "react";
import { signOut } from "./api";
import { useNavigate } from "react-router-dom";

import { ReactComponent as Logo } from "./assets/logo-no-ball.svg";

export const NavBar = () => {
  const { user, setUser } = useAppContext();
  const navigate = useNavigate();

  const onSignOut = React.useCallback(async () => {
    await signOut();
    setUser(null);
  }, []);

  return (
    <VStack w="100%">
      <HStack
        w="100%"
        p={3}
        justifyContent={user?.email ? "space-between" : "flex-end"}
        flexDir="row"
      >
        {user?.email ? <Logo width="150px" height="50px" /> : null}
        <Box>
          <Button
            colorScheme="teal"
            variant="outline"
            onClick={() => window.open("https://docs.balldontlie.io")}
            mr={4}
          >
            View Docs
          </Button>
          {user?.email ? (
            <Button onClick={onSignOut}>Log Out</Button>
          ) : (
            <>
              <Button
                colorScheme="teal"
                mr={4}
                onClick={() => navigate("/signup")}
              >
                Sign Up
              </Button>
              <Button variant="outline" onClick={() => navigate("/signin")}>
                Sign In
              </Button>
            </>
          )}
        </Box>
      </HStack>
      <Divider />
    </VStack>
  );
};
