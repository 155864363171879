import { useContext } from "react";
import { AppContext } from "./context";
import {
  Text,
  Button,
  Flex,
  HStack,
  Stat,
  StatGroup,
  StatLabel,
  StatNumber,
  VStack,
  useClipboard,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import {
  createCheckoutSession,
  createManageSubscriptionSession,
  registerStripeCustomer,
} from "./api";
import { PricingCard } from "./PricingCard";

export const Home = () => {
  const { user, setUser } = useContext(AppContext);
  const toast = useToast();
  const { onCopy, setValue, hasCopied } = useClipboard("");

  React.useEffect(() => {
    if (user?.apiKey) {
      setValue(user?.apiKey);
    }
  }, [user?.apiKey, setValue]);

  // If stripe_session_id is set, the customer arrived here after successfully checking out.
  // We need to call an endpoint that will fetch the stripe customer based on the session_id and update
  // the users.stripe_customer_id column.
  const query = new URLSearchParams(window.location.search);
  const sessionId = query.get("stripe_session_id");
  const addCustomer = React.useCallback(
    async (sid: string) => {
      try {
        const user = await registerStripeCustomer({ stripeSessionId: sid });
        setUser(user);
      } catch (e) {
        console.log(e);
      }
    },
    [setUser]
  );
  React.useEffect(() => {
    if (sessionId) {
      addCustomer(sessionId);
      toast({
        title: "Tier Upgraded",
        description:
          "It may take a few minutes for changes to take effect. Please check back in a minute.",
        status: "success",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionId, addCustomer]);

  const onNewCustomer = async (tier: "paid" | "paid_plus") => {
    const url = await createCheckoutSession(tier);
    window.open(url);
  };

  const subChange = query.get("stripe_subscription_change") === "true";
  React.useEffect(() => {
    if (subChange) {
      toast({
        title: "Account Changed",
        description: "It may take a few minutes for changes to take effect.",
        status: "success",
        isClosable: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subChange]);

  const onChangeSubscription = async () => {
    const url = await createManageSubscriptionSession();
    window.open(url);
  };

  const renderButtons = () => {
    if (!user?.stripeCustomerId || user?.tier === "free") {
      return (
        <VStack my={6} w="100%" flexShrink={0}>
          <Button
            variant="outline"
            colorScheme="teal"
            w="100%"
            onClick={() => onNewCustomer("paid")}
          >
            Upgrade to ALL-STAR
          </Button>
          <Button
            variant="solid"
            colorScheme="teal"
            w="100%"
            onClick={() => onNewCustomer("paid_plus")}
          >
            Upgrade to GOAT
          </Button>
        </VStack>
      );
    } else {
      return (
        <Button
          my={6}
          variant={user?.tier === "free" ? "solid" : "outline"}
          colorScheme="teal"
          w="100%"
          onClick={onChangeSubscription}
          flexShrink={0}
        >
          Change Plan
        </Button>
      );
    }
  };

  return (
    <Flex h="100%" my={4}>
      <Flex flex={2} />
      <Flex justify="center" align="center" h="100%">
        <VStack alignItems="flex-start" mb={6} h="100%">
          <StatGroup flexDir="column">
            <Stat>
              <StatLabel>Email</StatLabel>
              <StatNumber>{user?.email}</StatNumber>
            </Stat>
            <Stat my={5}>
              <StatLabel>
                <HStack>
                  <Text>API Key</Text>
                  <Button size="xs" onClick={onCopy}>
                    {hasCopied ? "Copied!" : "Copy"}
                  </Button>
                </HStack>
              </StatLabel>
              <StatNumber>{user?.apiKey}</StatNumber>
            </Stat>
            <Stat>
              <StatLabel>Tier</StatLabel>
              <StatNumber>
                {user?.tier === "free"
                  ? "Rookie"
                  : user?.tier === "paid"
                  ? "All-Star"
                  : "GOAT"}
              </StatNumber>
            </Stat>
            {user?.tier === "paid+" && (
              <Stat my={5}>
                <StatLabel>Private Support Channel</StatLabel>
                <StatNumber>https://discord.gg/ACCpN2RkwN</StatNumber>
              </Stat>
            )}
          </StatGroup>
          {renderButtons()}
          <HStack mt={5} align="flex-start">
            <PricingCard tier="free" />
            <PricingCard tier="paid" />
            <PricingCard tier="paid+" />
          </HStack>
        </VStack>
      </Flex>
      <Flex flex={2} />
    </Flex>
  );
};
