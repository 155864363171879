import { Text, Button, Heading, VStack, Stack } from "@chakra-ui/react";
import { ReactComponent as Logo } from "./assets/logo.svg";
import { useNavigate } from "react-router-dom";
import { PricingCard } from "./PricingCard";

export const Landing = () => {
  const navigate = useNavigate();
  return (
    <VStack w="100%">
      <VStack align="center" w="100%">
        <Logo width="50%" height="50%" />
        <Heading size="lg">
          The best NBA and betting odds API on the planet
        </Heading>
        <Button
          style={{ marginTop: 30 }}
          w={["90%", "20%"]}
          colorScheme="teal"
          onClick={() => navigate("/signup")}
        >
          Sign Up
        </Button>
        <Button
          w={["90%", "20%"]}
          colorScheme="teal"
          variant="outline"
          onClick={() => window.open("https://docs.balldontlie.io")}
        >
          View Docs
        </Button>
      </VStack>
      <Stack
        direction={["column", "row"]}
        style={{ marginTop: 175, marginBottom: 100 }}
      >
        <PricingCard tier="free" />
        <PricingCard tier="paid" />
        <PricingCard tier="paid+" />
      </Stack>
      <Stack
        direction={["column", "row"]}
        align="center"
        justify="center"
        padding="8px"
      >
        <Text>hello@balldontlie.io</Text>
        <Text>BALLDONTLIE LLC 2024</Text>
      </Stack>
    </VStack>
  );
};
